@tailwind base;

@tailwind components;

.tooltip {
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

/* start common table */
thead th {
  /* @ apply whitespace-nowrap; has to be on a case by case basis */
  @apply px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}

tbody td {
  /* @ apply whitespace-nowrap; has to be on a case by case basis */
  @apply px-2 py-4 text-sm leading-5 text-gray-500;
}
/* end common table */

/* start react modal */
.ReactModal__Overlay {
  background-color: rgba(10, 10, 10, 0.86);
}
.ReactModal__Overlay--before-open,
.ReactModal__Overlay--after-open {
  @apply ease-out duration-300;
}
.ReactModal__Overlay--before-open {
  @apply opacity-0;
}
.ReactModal__Overlay--after-open {
  @apply opacity-100;
}
.ReactModal__Overlay--before-close,
.ReactModal__Overlay--after-close {
  @apply ease-in duration-200;
}
.ReactModal__Overlay--before-close {
  @apply opacity-100;
}
.ReactModal__Overlay--after-close {
  @apply opacity-0;
}
/* end react modal */

/* start fancy scrollbar */
.u-fancy-scrollbar {
  -webkit-overflow-scrolling: touch;
  /* These cause the modal to behave weirdly. TODO: revisit when we have a new modal implementation */
  /* -webkit-transform: translateZ(0);
  transform: translateZ(0); */
}
.u-fancy-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.u-fancy-scrollbar::-webkit-scrollbar-button:end:increment,
.u-fancy-scrollbar::-webkit-scrollbar-button:start:decrement {
  background: transparent;
  display: none;
}
.u-fancy-scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(9, 30, 66, 0.08);
}
.u-fancy-scrollbar::-webkit-scrollbar-track-piece:vertical:start {
  border-radius: 4px 4px 0 0;
}
.u-fancy-scrollbar::-webkit-scrollbar-track-piece:vertical:end {
  border-radius: 0 0 4px 4px;
}
.u-fancy-scrollbar::-webkit-scrollbar-track-piece:horizontal:start {
  border-radius: 4px 0 0 4px;
}
.u-fancy-scrollbar::-webkit-scrollbar-track-piece:horizontal:end {
  border-radius: 0 4px 4px 0;
}
.u-fancy-scrollbar::-webkit-scrollbar-thumb:horizontal,
.u-fancy-scrollbar::-webkit-scrollbar-thumb:vertical {
  background: rgba(9, 30, 66, 0.13);
  border-radius: 4px;
  display: block;
  height: 48px;
}
.u-fancy-scrollbar::-webkit-scrollbar-button {
  display: block;
  height: 4px;
  width: 4px;
}
/* end fancy scrollbar */

@tailwind utilities;
