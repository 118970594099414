@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}

/* html:not(.candidate-processes) {
  overflow-x: auto;
} */

body:not(.candidate-processes) {
  min-width: 1280px;
}

p {
  margin-bottom: 0.5rem;
}

/* Notifications */
#notification-chrome-logged-in {
  display: none;
}
/* Notifications end */
