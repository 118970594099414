/* TinyMCE */
.editor-subject-field-wrapper .tox .tox-edit-area {
  border: 0 !important;
}

.tox .tox-statusbar {
  display: none !important;
}

/* keeping it consistent with Bulma */
.tox-tinymce {
  -webkit-appearance: none !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  position: relative !important;
  vertical-align: top !important;
  background-color: white !important;
  border-color: #dbdbdb !important;
  color: #363636 !important;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1) !important;
  max-width: 100% !important;
  width: 100% !important;
}

.tox-tinymce:hover {
  border-color: #b5b5b5 !important;
}

.tox-tinymce.focus {
  border-color: #3273dc !important;
  box-shadow: 0 0 0.125em rgba(50, 115, 220, 0.25) !important;
}
