
/* clear button */
.DateRangePickerInput_clearDates {
  @apply m-0 flex flex-row items-center right-0.5 justify-center min-w-md min-h-md
}

/* range separator */
.DateRangePickerInput_arrow {
  @apply px-1
}


/* inner inputs (start/end date) */
.DateRangePickerInput  {
  @apply flex flex-row items-center border-none overflow-hidden min-h-10 rounded-md;
}

.DateRangePicker input {
  box-shadow: none !important;
  border: none;
  min-height: 40px;
  @apply text-sm text-gray-900 transition-colors
}
.DateInput_input__focused {
  @apply bg-primary-50 !important
}

/* range days and hover while editing current range */
.CalendarDay__selected_span {
  @apply bg-primary-300 border-primary-200
}
.CalendarDay__selected_span:hover {
  @apply bg-primary-400 border-primary-200
}
.CalendarDay__selected:hover { 
  @apply bg-primary-900 border-primary-200
}

/* range days and hover day while empty range */
.CalendarDay__hovered_span {
  @apply border-primary-200 bg-primary-50 text-primary-500
}
.CalendarDay__hovered_span:hover { 
  @apply bg-primary-100 border-primary-200 text-primary-500
}

/* start and end days styles */
.CalendarDay__selected_start, .CalendarDay__selected_end  {
  @apply bg-primary-500 border-primary-200
}

/* gap between days and header */
.CalendarMonth_table {
  @apply mt-2
}


/* 
 *    Minor improvements
 */

/* transition */
.CalendarDay {
  @apply transition-colors
}

/* helper arrow at bottom */
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: rgb(126, 113, 198);
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right-color: rgb(71, 52, 174);
}