.quill,
.ql-container,
.ql-editor {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-style: inherit;
}
.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0 0 !important;
  border-style: inherit !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.quill .ql-container {
  border-color: currentColor;
  border-radius: 0 0 0.375rem 0.375rem;
  border: 0;
}
.ql-disabled {
  background-color: white !important;
}