/* font styles */
html {
  -webkit-font-smoothing: antialiased;
}

body, button, input, textarea {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
}

/** default 300px hide last row of days (calendar) (date-range-input.tsx) **/
.DayPicker_transitionContainer {
  height: 440px !important;
}

/** hide clear button for search inputs **/
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/** remove outline from inner input **/
.select__control * input {
  -webkit-appearance: none !important;
  box-shadow: none !important;
}
